import { Select } from '@jcm/poc-design-system';
import { Avatar, Col, Popover, Row } from 'antd';
import { memo, useContext } from 'react';

import { AuthContext, DataContext } from '../../../store';

import AvatarPopoverContent from './AvatarPopoverContent';

import colors from '../../../assets/scss/_colors.module.scss';
import styles from './index.module.scss';

const ContentLayoutHeader = memo(() => {
	const { user } = useContext(AuthContext);
	const { clienteID, setClienteID, isLoading, clientes } = useContext(DataContext);

	return (
		<Row className='justify-content-between w-100' id='default-content-layout-header'>
			<Col xl={3} className='d-flex align-items-center'>
				<Select
					id='header-cliente-id-select'
					className='w-100'
					options={clientes.map((x) => ({ label: x.Cliente, value: x.ClienteID.toString() }))}
					value={clienteID}
					onChange={setClienteID}
					loading={isLoading}
				/>
			</Col>

			<Col flex='auto' />

			<Col className='d-flex align-items-center' style={{ marginLeft: '14px' }}>
				<Popover
					overlayClassName={styles.popover}
					color={colors.inputBgContainer}
					placement='bottomLeft'
					trigger='click'
					arrow={false}
					content={<AvatarPopoverContent />}
				>
					<Avatar size='large' className={styles.avatar}>
						{user?.name.slice(0, 2).toLocaleUpperCase()}
					</Avatar>
				</Popover>
			</Col>
		</Row>
	);
});

export default ContentLayoutHeader;
