import { ConfigProvider } from '@jcm/poc-design-system';
import { App } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';

import Routes from './routes';
import {
	ApiContextProvider,
	AuthContextProvider,
	DataContextProvider,
	LocalStorageContextProvider,
	MessageContextProvider,
	SessionStorageContextProvider,
} from './store';

import reportWebVitals from './reportWebVitals';
import { sentrySetup } from './sentry';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

sentrySetup();

root.render(
	<React.StrictMode>
		<App>
			<ConfigProvider>
				<MessageContextProvider>
					<LocalStorageContextProvider>
						<SessionStorageContextProvider>
							<AuthContextProvider>
								<ApiContextProvider>
									<DataContextProvider>
										<Routes />
									</DataContextProvider>
								</ApiContextProvider>
							</AuthContextProvider>
						</SessionStorageContextProvider>
					</LocalStorageContextProvider>
				</MessageContextProvider>
			</ConfigProvider>
		</App>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
