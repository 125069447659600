import { HomeOutlined } from '@ant-design/icons';
import { DefaultContentLayout } from '@jcm/poc-design-system';
import { memo, useMemo } from 'react';

import ContentLayoutHeader from './ContentLayoutHeader';

import { MenuItem } from './index.types';

import logoBpo from '../../assets/images/logo-bpo.png';
import styles from './index.module.scss';

const ContentLayout = memo(() => {
	const menuItems: MenuItem[] = useMemo(() => [{ label: 'Home', key: '/', icon: <HomeOutlined /> }], []);

	return (
		<DefaultContentLayout
			id='content-layout'
			menuItems={menuItems}
			menuLogo={logoBpo}
			menuTitle=''
			menuId='content-layout-menu'
			header={<ContentLayoutHeader />}
			headerClassName={styles.header}
			headerId='content-layout-header'
			className={styles.layout}
			hasNavigation
			breadcrumb
		/>
	);
});

export default ContentLayout;
